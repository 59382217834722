import '../styles/Contact.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import WhatsappImage from '../assets/wa.png';
import FacebookImage from '../assets/facebookImage.png';
import InstagramImage from '../assets/inst.jpeg';
import TiktokImage from '../assets/tiktok.jpg';
import CallImage from '../assets/callp.png';
import XImage from '../assets/xImage.png';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Herosection from '../components/shareCoponent/heroSection';

export default function ContactUs() {
  const openWhatsApp = () => {
    const whatsappNumber = '2349068063045';
    const url = `https://wa.me/${whatsappNumber}`;
    window.open(url, '_blank');
  };

  const contactSections = [
    {
      icon: 'pi pi-comments',
      iconColor: '#ffca02',
      title: 'Email Our Support',
      description: 'Connect with our professional support team',
      email: 'support@movaex.com',
      type: 'mailto:support@movaex.com',
    },

    {
      icon: 'pi pi-phone',
      iconColor: '#ffca02',
      title: 'Call Us Now',
      description: 'Reach our front desk for a delivery request',
      email: '+234 906 806 3045',
      type: 'tel:+2349068063045',
    },

    {
      icon: 'pi pi-envelope',
      iconColor: '#ffca02',
      title: 'Make A Request',
      description: 'Reach out for partnership or report an issue',
      email: ' hello@movaex.com',
      type: 'mailto:hello@movaex.com',
    },
  ];
  return (
    <div className='contact__us__main '>
      {/* hero section component starts here */}
      <Herosection title='Contact Us' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-10 xl:w-9 m-auto'>
        <div className='first__section'>
          <h5 className='-mb-1 contact___paragraph__h5'>Contact Us Now</h5>

          <p className='contact___paragraph'>
            Request or schedule for a delivery now
          </p>

          <div className='grid m-auto'>
            <div className='col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6  '>
              <div className='card text-center my-auto py-2'>
                <img
                  onClick={openWhatsApp}
                  src={WhatsappImage}
                  alt=''
                  className='ads__social__image'
                />
              </div>
            </div>

            <div className='col-6 sm:col-6 md:col-6 lg:col-6 xl:col-6 '>
              <div className='card text-center my-auto py-2'>
                <a
                  href='tel:+2349068063045 '
                  target='_blank'
                  rel='noopener noreferrer'
                  className='footer__a'
                >
                  <img src={CallImage} alt='' className='ads__social__image' />
                </a>
              </div>
            </div>
          </div>

          {/* social cards start here */}

          <div className='social__title'>
            <h5 className='contact___paragraph__h5'>
              Follow Us On Our Social Medial
            </h5>
            <p className='contact___paragraph'>
              If you need assistance or have any questions, you can reach our
              support team through the following channels
            </p>
          </div>
          <div className='grid'>
            <div className='col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3 '>
              <div className='card text-center my-auto py-2'>
                <a
                  href='https://www.instagram.com/movaexlogistics/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='footer__a'
                >
                  <img
                    src={InstagramImage}
                    alt=''
                    className='ads__social__image'
                  />
                </a>
              </div>
            </div>

            <div className='col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3'>
              <div className='card text-center my-auto py-2'>
                <a
                  href='https://twitter.com/movaexLogistics'
                  className='footer__a'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={XImage} alt='' className='ads__social__image' />
                </a>
              </div>
            </div>

            <div className='col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3 '>
              <div className='card text-center my-auto py-2'>
                <a
                  href='https://www.facebook.com/profile.php?id=61555412589425&mibextid=ZbWKwL '
                  target='_blank'
                  rel='noopener noreferrer'
                  className='footer__a'
                >
                  <img
                    src={FacebookImage}
                    alt=''
                    className='ads__social__image'
                  />
                </a>
              </div>
            </div>

            <div className='col-6 sm:col-6 md:col-6 lg:col-3 xl:col-3 '>
              <div className='card text-center my-auto py-2'>
                <a
                  href='https://www.tiktok.com/@movaexlogistics?_t=8lEDjK8lKMQ&_r=1 '
                  target='_blank'
                  rel='noopener noreferrer'
                  className='footer__a'
                >
                  <img
                    src={TiktokImage}
                    alt=''
                    className='ads__social__image'
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='first__sectiond'>
          <h5 className='-mb-2 contact___paragraph__h5'>
            Frequently Asked Questions (FAQ)
          </h5>

          <p className='contact___paragraph'>
            Check our{' '}
            <span className='contact__span'>
              {' '}
              <a href='/faq' className='a___a'>
                {' '}
                FAQ page{' '}
              </a>{' '}
            </span>{' '}
            for answers to common questions and troubleshooting tips.
          </p>
        </div>

        <Divider />
      </div>

      <section className='w-10 md:w-10 m-auto'>
        <div className='grid mt-2 md:mt-8'>
          {contactSections.map((section, index) => (
            <div
              key={index}
              className='col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4  '
            >
              <div className='contact__grid__section py-4  px-1 text-center'>
                <span className='contact__icon px-2 py-1'>
                  <i
                    className={section.icon}
                    style={{ fontSize: '1.6rem', color: section.iconColor }}
                  ></i>{' '}
                </span>
                <p className='contact__h6 '>{section.title}</p>
                <p className='my-1 contact__paragrph'>{section.description}</p>
                <p className='last__p'>
                  <a href={section.type} className='span'>
                    {section.email}{' '}
                  </a>
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className='form__section' data-aos='fade-down'>
        <form className='w-full md:w-11 lg:w-10 xl:w-10 m-auto ___form'>
          <div className='grid'>
            <div className='col-12 md:col-12 lg:col-12 xl:col-12 '>
              <h2 className='contact__me__h2'>Contact Me</h2>
              <p className='contact___p'>
                If you have any inquiries or if there's anything I can help you
                with, don't hesitate to get in touch.
              </p>
            </div>
            <div className='col-12 md:col-12 lg:col-12 xl:col-12'>
              <InputText
                id='text'
                type='text'
                placeholder='Full Name'
                className='w-full shadow-1 border-none text-sm p-inputtext-lg'
              />
            </div>
            <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
              <InputText
                id='email'
                type='email'
                placeholder='Email Addres'
                className='w-full shadow-1 border-none text-sm p-inputtext-lg'
              />
            </div>
            <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
              <InputText
                id='phone'
                type='phone'
                placeholder='Phone Number'
                className='w-full shadow-1 border-none text-sm p-inputtext-lg'
              />
            </div>
            <div className='col-12 md:col-12 lg:col-12 xl:col-12'>
              <InputTextarea
                autoResize
                rows={9}
                cols={30}
                className='w-full shadow-1 border-none text-bold text-sm p-inputtext-lg'
                placeholder='Type your message'
              />
            </div>
            <div className='col-12 md:col-12 lg:col-12 xl:col-12 text-center'>
              <Button
                label='Send Message'
                icon='pi pi-angle-double-right'
                className=' px-6 py-4 border-none white-space-nowrap  ___btn'
                iconPos='right'
              />
            </div>
          </div>
        </form>
      </section>

      <div className='main'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5132897988415!2d7.51585237475265!3d6.4564576935350635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a3f3713cc481%3A0xa694b0bb4bc994d3!2s18%20Marcus%20Garvey%20St%2C%20New%20Haven%2C%20Enugu%20400102%2C%20Enugu!5e0!3m2!1sen!2sng!4v1702971635956!5m2!1sen!2sng'
          title='Google Maps'
          className='map___style'
        ></iframe>
      </div>
    </div>
  );
}
